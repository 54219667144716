import React from "react";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SearchableSelector from "../../SearchableSelector";
import { generateReqId } from "../../../utils/generateReqId";
import { generatePaymentRef } from "../../../utils/generatePaymentRef";
import { generateUrlId } from "../../../utils/generateUrlID";

const PayWithAccount = ({ handleClose, setOTPModal, OTPModal }) => {
  const { success, snackError, cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [options, setOptions] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(false);
  console.log(cookies.auth.user);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(
        `https://services.staging.wayabank.ng/temporal-service/api/v1/wallet/otp/generate/${cookies?.auth?.user?.phoneNumber}`
      );
      success(response?.data?.data?.message);
      setOTPModal((prev) => ({
        ...prev,
        open: true,
        values: values,
        caller: "payToAccount",
      }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }

    // handleClose();
  };

  const makeTransfer = async () => {
    const { recipientAccountNumber } = OTPModal.values;
    const { acct_name, accountNo } = OTPModal.values?.fromUser || {};
    try {
      const response = await axiosPrivate.post(
        "/logs-service/api/v1/requestlog/create",
        {
          customerName: acct_name,
          payload: JSON.stringify({
            benefAccountNumber: recipientAccountNumber,
            debitAccountNumber: accountNo,
            paymentReference: generateUrlId(),
            tranCrncy: "NGN",
            tranNarration: "internal transfer",
            tranType: "LOCAL",
            userId: cookies.auth.user.id,
          }),
          requestId: generateReqId(),
          requestType: "TRANSFERToAccount",
        }
      );
      success(response.data?.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (OTPModal.status?.payToAccount == "success") {
      makeTransfer();
    }
  }, [OTPModal.status?.payToAccount]);

  const getWallets = async ({ userId }) => {
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/wallet/admin/user/accounts/" + userId
      );
      setOptions(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (e, newValue, setFieldValue) => {
    setFieldValue("username", newValue);
    getWallets(newValue);
  };

  const initialValues = {
    username: null,
    recipientAccountNumber: "",
    recipientAccountName: "",
    amount: "",
    narration: "",
    pin: "",
    fromUser: "",
  };

  const checkoutSchema = yup.object().shape({
    username: yup.object().required("Required"),
    recipientAccountNumber: yup.number().required("Required"),
    recipientAccountName: yup.string().required("Required"),
    amount: yup.number().required("Required"),
    narration: yup.string(),
    pin: yup.number().required("Required"),
    fromUser: yup.object().required("Required"),
  });

  return (
    <Box width="100%">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box padding={5}>
              <Typography
                variant="h3"
                sx={{ textAlign: "center", marginBottom: "30px" }}
              >
                Pay To Account
              </Typography>
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                  marginBottom: "10px",
                }}
              >
                <SearchableSelector
                  value={values.username}
                  name="username"
                  sx={{ gridColumn: "span 2" }}
                  handleChange={(e, newValue) =>
                    handleSelectChange(e, newValue, setFieldValue)
                  }
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Recipient Account Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.recipientAccountNumber}
                  name="recipientAccountNumber"
                  error={
                    !!touched.recipientAccountNumber &&
                    !!errors.recipientAccountNumber
                  }
                  helperText={
                    touched.recipientAccountNumber &&
                    errors.recipientAccountNumber
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Recipient Account Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.recipientAccountName}
                  name="recipientAccountName"
                  error={
                    !!touched.recipientAccountName &&
                    !!errors.recipientAccountName
                  }
                  helperText={
                    touched.recipientAccountName && errors.recipientAccountName
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  error={!!touched.amount && !!errors.amount}
                  helperText={touched.amount && errors.amount}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Add a note"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.narration}
                  name="narration"
                  error={!!touched.narration && !!errors.narration}
                  helperText={touched.narration && errors.narration}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Pin"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pin}
                  name="pin"
                  error={!!touched.pin && !!errors.pin}
                  helperText={touched.pin && errors.pin}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Transfer From"
                  fullWidth
                  select
                  name="fromUser"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fromUser}
                  sx={{ gridColumn: "span 4" }}
                >
                  {options?.map((option, index) => {
                    const { acct_name, accountNo } = option;
                    return (
                      <MenuItem key={index} value={option}>
                        {acct_name + " - " + accountNo}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
              <Button
                type="submit"
                color="secondary"
                disabled={isLoading}
                variant="contained"
                sx={{ width: "100%", p: 2, mt: 2 }}
              >
                Proceed
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default PayWithAccount;
