import { Box, Button, useTheme, MenuItem, Menu } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import SearchTableWithDatePicker from "../../components/SearchTableWithDatePicker";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import format from "date-fns/format";
import TransactionFormModal from "../../components/modals/TransactionFormModal";
import TransferToOwnAccountModal from "../../components/modals/TransferToOwnAccountModal";
import TransferToOtherAccountModal from "../../components/modals/TransferToOtherAccountModal";
import TransferToOtherBankModal from "../../components/modals/TransferToOtherBankModal";
import UtilityBillModal from "../../components/modals/transferModals/UtilityBillModal";
import CableModal from "../../components/modals/transferModals/CableModal";
import AirtimeModal from "../../components/modals/transferModals/AirtimeModal";
import DataModal from "../../components/modals/transferModals/DataModal";
import BettingModal from "../../components/modals/transferModals/BettingModal";
import { ViewTransactionModal } from "../../components/modals/ViewTransactionModal";
import WarningModal from "../../components/modals/WarningModal";
import GenericPdfDownloader from "../../utils/downloadAsPdf";
import OTPTransactionModal from "../../components/modals/OTPTransactionModal";
import ExportToCSV from "../../utils/exportToCSV";

const AllTransctions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [details, setDetails] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modal, setModal] = useState({ open: false, title: "" });
  const [utilityBillModal, setUtilityBillModal] = useState({ open: false });
  const [cableModal, setCableModal] = useState({ open: false });
  const [viewTransModal, setViewTransModal] = useState({
    open: false,
    data: null,
  });
  const [airtimeModal, setAirtimeModal] = useState({ open: false });
  const [dataModal, setDataModal] = useState({ open: false });
  const [bettingModal, setBettingModal] = useState({ open: false });
  const [reverseModal, setReverseModal] = useState({ openConfirm: false });
  const [transferToOtherBankModal, setTransferToOtherBankModal] = useState({
    open: false,
  });
  const [transferToOtherAccountModal, setTransferToOtherAccountModal] =
    useState({ open: false });
  const [transferToOwnAccountModal, setTransferToOwnAccountModal] = useState({
    open: false,
  });
  const axiosPrivate = useAxiosPrivate();
  const { range, success, snackError } = useAuth();
  const [OTPModal, setOTPModal] = useState({
    open: false,
    caller: "",
    status: {
      otherBank: null,
      ownAccount: null,
      payWithEmail: null,
      payWithWayaId: null,
      payToPhone: null,
      payToAccount: null,
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleOption = (e) => {
    if (e.target.textContent == "Transfer to Other bank") {
      setTransferToOtherBankModal({ open: true });
    } else if (e.target.textContent == "Recharge Airtime") {
      setAirtimeModal({ open: true });
    } else if (e.target.textContent == "Recharge Data") {
      setDataModal({ open: true });
    } else if (e.target.textContent == "Pay for Cable TV") {
      setCableModal({ open: true });
    } else if (e.target.textContent == "Pay for Utility Bill") {
      setUtilityBillModal({ open: true });
    } else if (e.target.textContent == "Pay for Betting") {
      setBettingModal({ open: true });
    } else {
      setModal({ open: true, title: e.target.textContent });
    }
    setAnchorEl(null);
  };

  const handleSearch = async ({ searchKey }) => {
    console.log({ range });
    const trimedKey = searchKey.trim();
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/wallet/transaction-list",
        {
          params: {
            fromdate: format(range[0]?.startDate, "yyyy-MM-dd"),
            todate: format(range[0]?.endDate, "yyyy-MM-dd"),
          },
        }
      );
      console.log(response?.data?.data?.transaction);

      const result = response?.data?.data?.transaction.filter(
        (transaction) =>
          transaction.tranId === trimedKey ||
          transaction.acctNum === trimedKey ||
          transaction.createdEmail === trimedKey ||
          transaction.paymentReference === trimedKey
      );
      setDetails(result);
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  const handleOTPSubmit = async (otp, phoneNumber, setIsSubmitting) => {
    setIsSubmitting(true);
    try {
      const response = await axiosPrivate.post(
        "/temporal-service/api/v1/wallet/otp/payment/verify",
        {
          otp,
          phoneOrEmail: phoneNumber,
        }
      );
      success(response?.data?.data?.message);
      setOTPModal({
        open: false,
        values: OTPModal.values,
        status: {
          [OTPModal.caller]: "success",
        },
      });
    } catch (error) {
      snackError(error?.response?.data?.message);
      setOTPModal({
        open: false,
        values: OTPModal.values,
        status: { [OTPModal.caller]: "failed" },
      });
    }
    setIsSubmitting(false);
  };

  const getTransactions = async () => {
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 10);
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/wallet/transaction-list",
        {
          params: {
            fromdate: format(date, "yyyy-MM-dd"),
            todate: format(new Date(), "yyyy-MM-dd"),
          },
        }
      );
      const result = response?.data?.data?.transaction;
      setDetails(result);
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);
  const columns = [
    { field: "paymentReference", headerName: "Reference Id", width: 160 },
    {
      field: "createdAt",
      headerName: "Date",
      width: 90,
      renderCell: ({ row: { createdAt } }) => createdAt.toString().slice(0, 10),
    },
    {
      field: "acctNum",
      headerName: "Sender Account",
      cellClassName: "name-column--cell",
      width: 130,
    },
    {
      field: "tranType",
      headerName: "Category",
      width: 80,
    },
    {
      field: "tranAmount",
      headerName: "Amount",
      cellClassName: "name-column--cell",
      width: 100,
    },
    {
      field: "tranNarrate",
      headerName: "Narration",
      cellClassName: "name-column--cell",
      width: 130,
    },
    {
      field: "partTranType",
      headerName: "Type",
      cellClassName: "name-column--cell",
      width: 70,
      renderCell: ({ row: { partTranType } }) => {
        return partTranType == "C" ? "Credit" : "Debit";
      },
    },
    {
      field: "recieverName",
      headerName: "Recipient",
      cellClassName: "name-column--cell",
      width: 110,
    },
    {
      field: "action",
      headerName: "Action",
      cellClassName: "name-column--cell",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Button
              onClick={() => setViewTransModal({ open: true, data: row })}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                ml: 1,
                color: colors.grey[100],
              }}
            >
              View
            </Button>
            <Button
              onClick={() =>
                setReverseModal({
                  openConfirm: true,
                  user: row,
                  action: "reverse transaction",
                  url: "/logs-service/api/v1/requestlog/create",
                })
              }
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[600],
                ml: 1,
                color: colors.grey[100],
              }}
            >
              Reverse
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <WarningModal
        open={reverseModal.openConfirm}
        url={reverseModal.url}
        action={reverseModal.action}
        user={reverseModal.user}
        setOpen={setReverseModal}
      />
      <ViewTransactionModal
        modal={viewTransModal}
        setModal={setViewTransModal}
      />
      <TransactionFormModal
        isOpen={modal.open}
        title={modal.title}
        setModal={setModal}
        setTransferToOtherAccountModal={setTransferToOtherAccountModal}
        setTransferToOwnAccountModal={setTransferToOwnAccountModal}
      />
      <TransferToOwnAccountModal
        OTPModal={OTPModal}
        setOTPModal={setOTPModal}
        isOpen={transferToOwnAccountModal}
        setModal={setTransferToOwnAccountModal}
      />
      <TransferToOtherAccountModal
        OTPModal={OTPModal}
        setOTPModal={setOTPModal}
        isOpen={transferToOtherAccountModal}
        setModal={setTransferToOtherAccountModal}
      />
      <TransferToOtherBankModal
        OTPModal={OTPModal}
        setOTPModal={setOTPModal}
        isOpen={transferToOtherBankModal}
        setModal={setTransferToOtherBankModal}
      />
      <UtilityBillModal
        modal={utilityBillModal}
        setModal={setUtilityBillModal}
      />
      <CableModal modal={cableModal} setModal={setCableModal} />
      <AirtimeModal modal={airtimeModal} setModal={setAirtimeModal} />
      <DataModal modal={dataModal} setModal={setDataModal} />
      <BettingModal modal={bettingModal} setModal={setBettingModal} />
      <OTPTransactionModal
        modal={OTPModal}
        setModal={setOTPModal}
        handleOTPSubmit={handleOTPSubmit}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="TRANSACTIONS" subtitle="Manage all transactions" />
        <Box display="flex">
          <ExportToCSV itemsNotFormatted={details} />
          <GenericPdfDownloader
            data={details}
            downloadFileName="Transactions"
          />
          <SearchTableWithDatePicker
            handleSearch={handleSearch}
            placeholder="ref. id/trans. id/email/account no."
          />
          <button onClick={handleSearch}>
            Search
          </button>
          <Box>
            <Button
              onClick={(e) => toggleMenu(e)}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "15px",
              }}
            >
              <AddIcon sx={{ mr: "10px" }} />
              Perform Transaction for User
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              classes={{ paper: "dropdown-menu" }}
            >
              <MenuItem onClick={(e) => handleOption(e)}>
                Transfer to Wayabank
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                Transfer to Other bank
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                Recharge Airtime
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                Recharge Data
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                Pay for Cable TV
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                Pay for Utility Bill
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                Pay for Betting
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Box
          m="10px"
          width="100%"
          height="75vh"
          id="gridTable"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={details ? details : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default AllTransctions;
