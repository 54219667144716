import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import IconMenu from "../../components/IconMenu";
import NewTerminalModal from "../../components/modals/NewTerminalModal";
import { customBaseUrl, httpGet } from "../../action/https";

const ManageTerminals = () => {
  const [details, setDetails] = useState([]);
  const [modal, setModal] = useState({ open: false });
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getTerminals = async () => {
      // try {
      //   const response = await axiosPrivate.get(
      //     "/terminal-service/api/v1/terminals/getterminaltypes"
      //   );
      //   setDetails(response?.data?.respBody);
      // } catch (error) {
      //   console.log(error);
      // }
      try {
        const response = await httpGet(`${customBaseUrl.posUrl}/terminal-service/api/v1/terminals/getterminaltypes`)
        
        setDetails(response?.respBody);
      } catch (error) {
        console.log(error);
      }
    };

    getTerminals();
  }, []);

  const columns = [
    { field: "terminalName", headerName: "Type Name", width: 200 },
    {
      field: "terminalCategory",
      headerName: "Terminal Category",
      width: 200,
    },
    {
      field: "amount",
      headerName: "Terminal Cost",
      width: 100,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      cellClassName: "name-column--cell",
      width: 100,
    },
    {
      field: "",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div data-gid={`row${params.id}-col-actions`}>
            <IconMenu rowId={params.id} /> {/* Render the icon for the menu */}
          </div>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}

      <NewTerminalModal
        isOpen={modal.open}
        setModal={setModal}
        terminalTypes={details}
      />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header title="TERMINALS" subtitle="Manage Terminals Types" />
          </Box>
        </Grid>
        {/* <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display="flex"
            justifyContent="end"
            paddingRight={1.2}
            paddingTop={1}
          >
            <Button
              underline="none"
              onClick={() => setModal({ open: true })}
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "15px",
              }}
            >
              <AddIcon sx={{ mr: "10px" }} />
              Create Terminal Type
            </Button>
          </Box>
        </Grid> */}
        <Box
          m="10px"
          width="100%"
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={details}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ManageTerminals;
