import React, { useState, useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { resolvePath, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Searchbox from "../../components/Searchbox";
import ModalWithTabs from "../../components/modals/ModalWithTabs";
import WarningModal from "../../components/modals/WarningModal";
import UploadSpreadSheetModal from "../../components/modals/UploadSpreadSheetModal";
import MoreFormModal from "../../components/modals/MoreFormModal";
import CreateWalletModal from "../../components/modals/CreateWalletModal";
import CreateAccountModal from "../../components/modals/CreateAccountModal";
import MultipleFormModal from "../../components/modals/MultipleFormModal";
import { format } from "date-fns";

const SEARCH_BY_EMAIL = "/auth-service/api/v1/user/email/";
const SEARCH_BY_PHONE = "/auth-service/api/v1/user/phone/";

const ManageUser = () => {
  const theme = useTheme();
  const { queryUserDetails, info, snackError, setQueryUserDetails, success } =
    useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const [createUserAnchorEl, setCreateUserAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({ open: false, title: "" });
  const [walletModal, setWalletModal] = useState({ open: false });
  const [multipleFormModal, setMultipleFormModal] = useState({
    open: false,
    title: "",
  });
  const [accountModal, setAccountModal] = useState({ open: false });
  const [spreadSheetModal, setSpreadSheetModal] = useState({
    open: false,
    title: "",
  });
  const open = Boolean(anchorEl);
  const createUserOpen = Boolean(createUserAnchorEl);
  const [{ action, url, openConfirm }, setConfirm] = useState({
    openConfirm: false,
    url: "",
    action: "",
  });

  let { user } = queryUserDetails;

  const toggleMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const toggleCreateUserMenu = (event) => {
    setCreateUserAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateUserClose = () => {
    setCreateUserAnchorEl(null);
  };

  const handleOption = async (e) => {
    setAnchorEl(null);
    let response, response2, response3;
    if (e.target.textContent === "Manage Profile") {
      try {
        response = await axiosPrivate.get(
          "/auth-service/api/v1/profile/" + user[0]?.id
        );

        response2 = await axiosPrivate.get(
          "/temporal-service/api/v1/wallet/trans-type/status/" + user[0]?.id
        );

        setQueryUserDetails((prev) => {
          return {
            ...prev,
            manageProfile: response?.data?.data,
            transConfig: response2?.data?.data,
          };
        });
        navigate("/update_user");
      } catch (error) {
        snackError(error?.response?.data?.message);
      }
    } else if (e.target.textContent === "Manage Accounts") {
      try {
        response = await axiosPrivate.get(
          "/temporal-service/api/v1/wallet/admin/user/accounts/" +
            user[0]?.id
        );
        response2 = await axiosPrivate.get(
          "/account-service/account/getAccounts/" + user[0]?.id
        );
        response3 = await axiosPrivate.get(
          "/card-service/card/list/" + user[0]?.id
        );
        console.log(response3);
        setQueryUserDetails((prev) => {
          return {
            ...prev,
            manageAccount: response2?.data?.data,
            manageTransaction: response?.data?.data,
          };
        });
        setIsOpen(true);
      } catch (error) {
        snackError(error?.response?.data?.message);
      }
    } else if (e.target.textContent === "Block User") {
      setConfirm({
        url: "/auth-service/api/v1/admin/users/lock-toggle/un-lock",
        openConfirm: true,
        action: "block",
      });
    } else if (e.target.textContent === "Unblock User") {
      setConfirm({
        url: "/auth-service/api/v1/admin/users/lock-toggle/un-lock",
        openConfirm: true,
        action: "unblock",
      });
    } else if (e.target.textContent === "Deactivate User") {
      setConfirm({
        url: "/logs-service/api/v1/requestlog/create",
        openConfirm: true,
        action: "deactivate",
      });
    } else if (e.target.textContent === "Activate User") {
      setConfirm({
        url: "/logs-service/api/v1/requestlog/create",
        openConfirm: true,
        action: "activate",
      });
    } else if (e.target.textContent === "Reset Credential") {
      setConfirm({
        url: "/auth-service/api/v1/password/change-password/byEmail",
        openConfirm: true,
        action: "reset",
      });
    } else if (e.target.textContent === "Lock Transaction") {
      setConfirm({
        url: "/auth-service/api/v1/admin/users/lock-toggle/un-lock",
        openConfirm: true,
        action: "lock",
      });
    } else if (e.target.textContent === "Manage KYC") {
      try {
        response2 = await axiosPrivate.get(
          "/auth-service/api/v1/user/" + user[0]?.id
        );
        setQueryUserDetails((prev) => {
          return { ...prev, manageKYC: response2?.data?.data };
        });
        navigate("/user_kyc");
      } catch (error) {
        snackError(error?.response?.data?.message);
      }
    } else if (e.target.textContent === "Login Tracker") {
      try {
        response = await axiosPrivate.get(
          "/auth-service/api/v1/history/user-history/" + user[0]?.id
        );
        setQueryUserDetails((prev) => {
          return { ...prev, loginTracker: response?.data?.data };
        });
        navigate("/login_tracker");
      } catch (error) {
        snackError(error?.response?.data?.message);
      }
    } else if (e.target.textContent === "Create Single User") {
      setModal({ open: true, title: e.target.textContent });
    } else if (e.target.textContent === "Upload Spread Sheet") {
      setSpreadSheetModal({ open: true, title: e.target.textContent });
    } else if (e.target.textContent === "Create Users with Forms") {
      setMultipleFormModal({ open: true, title: e.target.textContent });
    }
  };

  const handleSearch = async ({ searchKey }) => {
    const emailRegex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    const phoneRegex = /^(\+234-|\+234|0)?\d{10,13}$/;

    let response;
    if (searchKey.match(emailRegex)) {
      try {
        response = await axiosPrivate.get(
          SEARCH_BY_EMAIL + encodeURIComponent(searchKey)
        );
      } catch (error) {
        snackError(error.response.data.message);
      }
    } else if (searchKey.match(phoneRegex)) {
      try {
        response = await axiosPrivate.get(SEARCH_BY_PHONE + searchKey);
      } catch (error) {
        snackError(error.response.data.message);
      }
    } else if (searchKey === "") {
      setQueryUserDetails({
        user: [],
        manageProfile: {},
        manageAccount: {},
        manageTransaction: {},
        manageKYC: {},
      });
    } else {
      setQueryUserDetails({
        user: [],
        manageProfile: {},
        manageAccount: {},
        manageTransaction: {},
        manageKYC: {},
      });
      info("Not a valid email or phone");
    }

    setQueryUserDetails((prev) => {
      return { ...prev, user: [response?.data?.data] };
    });
  };
  const getUsers = async () => {
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);
    try {
      const response = await axiosPrivate.get(
        "/auth-service/api/v1/admin/users-list",
        {
          params: {
            fromDate: format(date, "yyyy-MM-dd"),
            toDate: format(new Date(), "yyyy-MM-dd"),
          },
        }
      );
      const result = response?.data?.data;
      setQueryUserDetails((prev) => {
        return { ...prev, user: result };
      });
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);
  const columns = [
    { field: "id", headerName: "Id", width: 50 },
    {
      field: "firstName",
      headerName: "First Name",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "surname",
      headerName: "Last Name",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "dateOfBirth",
      headerName: "Date of Birth",
      headerAlign: "left",
      align: "left",
    },
    { field: "phoneNumber", headerName: "Phone Number", width: 120 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "active",
      headerName: "Status",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row: { active } }) => (
        <Typography
          color={active ? colors.greenAccent[400] : colors.redAccent[400]}
        >
          {active ? "active" : "inactive"}
        </Typography>
      ),
    },
    {
      field: "accountLocked",
      headerName: "Locked",
      type: "boolean",
      width: 70,
      renderCell: ({ row: { accountLocked } }) => (
        <Typography
          color={
            accountLocked ? colors.greenAccent[400] : colors.redAccent[400]
          }
        >
          {accountLocked ? accountLocked.toString() : "false"}
        </Typography>
      ),
    },
    {
      field: "accountDeleted",
      headerName: "Deleted",
      type: "boolean",
      width: 70,
      renderCell: ({ row: { accountDeleted } }) => (
        <Typography
          color={
            accountDeleted ? colors.greenAccent[400] : colors.redAccent[400]
          }
        >
          {accountDeleted ? accountDeleted.toString() : "false"}
        </Typography>
      ),
    },
    {
      field: "accountExpired",
      headerName: "Expired",
      type: "boolean",
      width: 70,
      renderCell: ({ row: { accountExpired } }) => (
        <Typography
          color={
            accountExpired ? colors.greenAccent[400] : colors.redAccent[400]
          }
        >
          {accountExpired ? accountExpired.toString() : "false"}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 60,
      align: "center",
      renderCell: ({ row: { active, accountLocked } }) => {
        const handleActionsClick = (event) => {
          // Handle actions click event here
          toggleMenu(event);
        };

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              boxShadow: "none",
            }}
          >
            <MoreVertIcon
              onClick={handleActionsClick}
              style={{ cursor: "pointer", fontSize: "20px" }}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={(e) => handleOption(e)}>
                Manage Profile
              </MenuItem>
              {/* <MenuItem onClick={(e) => handleOption(e)}>Manage Team</MenuItem> */}
              <MenuItem onClick={(e) => handleOption(e)}>
                Manage Accounts
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>Manage KYC</MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                Login Tracker
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>Activity Log</MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                Reset Credential
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                {active ? "Deactivate User" : "Activate User"}
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                {accountLocked ? "Unblock User" : "Block User"}
              </MenuItem>
              {/* <MenuItem onClick={(e) => handleOption(e)}>
                Lock Transaction
              </MenuItem> */}
            </Menu>
          </div>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <ModalWithTabs
        setWalletModal={setWalletModal}
        setAccountModal={setAccountModal}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <WarningModal
        url={url}
        action={action}
        open={openConfirm}
        setOpen={setConfirm}
        user={user[0]}
      />
      <UploadSpreadSheetModal
        open={spreadSheetModal.open}
        setModal={setSpreadSheetModal}
        title={spreadSheetModal.title}
      />
      <MoreFormModal
        isOpen={modal.open}
        setModal={setModal}
        title={modal.title}
      />
      <MultipleFormModal
        modal={multipleFormModal}
        setModal={setMultipleFormModal}
      />
      <CreateWalletModal isOpen={walletModal.open} setModal={setWalletModal} />
      <CreateAccountModal
        isOpen={accountModal.open}
        setModal={setAccountModal}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="USERS MANAGEMENT"
          subtitle="Manage Users Information and Transactions"
        />
        <Box display="flex">
          <Searchbox handleSearch={handleSearch} />
          <Button
            onClick={(e) => toggleCreateUserMenu(e)}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "15px",
            }}
          >
            <AddIcon sx={{ mr: "10px" }} />
            Create Users
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={createUserAnchorEl}
            open={createUserOpen}
            onClose={handleCreateUserClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            //   classes={{ paper: "dropdown-menu2" }}
          >
            <MenuItem onClick={(e) => handleOption(e)}>
              Create Single User
            </MenuItem>
            <MenuItem onClick={(e) => handleOption(e)}>
              Upload Spread Sheet
            </MenuItem>
            <MenuItem onClick={(e) => handleOption(e)}>
              Create Users with Forms
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box
        m="8px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "#fff solid",
          },
          "& .name-column--cell": {
            color: colors.orangeAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.redAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.redAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.orangeAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          getRowId={(row) => {
            return row?.loginId;
          }}
          rows={user[0] ? user : []}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default ManageUser;
