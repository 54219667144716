import React, { useState } from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "../../api/axios";
import PasswordField from "../../components/PasswordField";
import useAuth from "../../hooks/useAuth";
import ForgetPasswordOTPModal from "../../components/modals/ForgetPasswordOTPModal";
import OTPModal from "../../components/modals/OTPModal";

const LOGIN_URL = "/auth-service/api/v1/auth/login";
const OTP_URL = "/auth-service/api/v1/auth/generate-login-otp/";

const Login = () => {
  const { login, success, snackError } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [modal, setModal] = useState({ open: false });
  const [otpModal, setOTPModal] = useState({
    open: false,
    emailOrPhone: "",
    word: "",
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleOTPSubmit = async (otp, setIsSubmitting) => {
    setIsSubmitting(true);
    try {
      const response = await axios.postForm(
        LOGIN_URL,
        JSON.stringify({
          emailOrPhoneNumber: otpModal.emailOrPhone,
          otp: otp.join(""),
          password: otpModal.word,
        })
      );
      const { user, token } = response?.data?.data;
      localStorage.setItem('token', token);
      login(JSON.stringify({ user, token }));
      success(response.data.message);
      navigate(from, { replace: true });
    } catch (error) {
      snackError(error.response.data.message);
    }
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (values, actions) => {
    const { emailOrPhone, password } = values;
    try {
      const response = await axios.post(
        OTP_URL + encodeURIComponent(emailOrPhone),
        {},
        {
          headers: {
              "CLIENT-ID": 'WAYABANK',
              'CLIENT-TYPE': 'ADMIN'
          }
        }
      );
      success(response.data.message);
      setOTPModal((prev) => ({
        ...prev,
        open: true,
        emailOrPhone: emailOrPhone,
        word: password,
      }));
      actions.resetForm();
    } catch (error) {
      snackError(error.response.data.message);
      try {
        const response = await axios.postForm(
          LOGIN_URL,
          JSON.stringify({
            emailOrPhoneNumber: emailOrPhone,
            otp: "",
            password: password,
          }),
          {
            headers: {
                "CLIENT-ID": 'WAYABANK',
                'CLIENT-TYPE': 'ADMIN'
            }
          }
        );
        const { user, token } = response?.data?.data;
        localStorage.setItem('token', token);
        login(JSON.stringify({ user, token }));
        success(response.data.message);
        navigate(from, { replace: true });
      } catch (error) {
        snackError(error.response.data.message);
      }
    }
  };

  const initialValues = {
    emailOrPhone: "",
    password: "",
  };

  const checkoutSchema = yup.object().shape({
    emailOrPhone: yup
      .string("Enter your Email/Phone Number")
      .required("Email/Phone Number is required")
      .test("emailOrPhone", "Enter Valid Phone/Email", function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        const phoneRegex = /^(\+234-|\+234|0)?\d{10}$/; // Change this regex based on requirement
        let isValidEmail = emailRegex.test(value);
        let isValidPhone = phoneRegex.test(value);
        if (!isValidEmail && !isValidPhone) {
          return false;
        }
        return true;
      }),
    password: yup.string().min(3).required("Required"),
  });

  return (
    <Box
      m="20px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="90vh"
      alignItems="center"
      textAlign="center"
    >
      <OTPModal
        setModal={setOTPModal}
        modal={otpModal}
        handleOTPSubmit={handleOTPSubmit}
      />
      <ForgetPasswordOTPModal modal={modal} setModal={setModal} />
      <Box mb="30px">
        <Typography
          variant="h2"
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{ mb: "5px" }}
        >
          LOGIN
        </Typography>
        <Typography variant="h5" color={colors.orangeAccent[600]}>
          Login to see Dashboard
        </Typography>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(2, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email / Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.emailOrPhone}
                name="emailOrPhone"
                error={!!touched.emailOrPhone && !!errors.emailOrPhone}
                helperText={touched.emailOrPhone && errors.emailOrPhone}
                sx={{ gridColumn: "span 16" }}
              />
              <PasswordField
                fullWidth
                variant="filled"
                type={showPassword ? "text" : "password"}
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 16" }}
                iconEnd={<Visibility />}
                setShowPassword={setShowPassword}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                fullWidth
                disabled={isSubmitting}
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ gridColumn: "span 16", p: 2, mt: 1 }}
              >
                Login
              </Button>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                onClick={() => setModal({ open: true })}
                sx={{ color: colors.orangeAccent[600], fontSize: "16px" }}
              >
                forget password?
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Login;
