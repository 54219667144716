import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { CounterField } from "../CounterField";
import { tokens } from "../../theme";
import MerchantSearchableSelector from "../MerchantSearchableSelector";
import { makeStyles } from "@mui/styles";
import { customBaseUrl, httpPost } from "../../action/https";

const useStyles = makeStyles({
  radioGroup: {
    display: "flex",
    flexDirection: "row",
  },
});

const RequestTerminalModal = ({ isOpen, setModal, terminalTypes }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const [value, setValue] = useState(null);
  const [merchantDetails, setMerchantDetail] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [terminals, setTerminals] = useState({
    [terminalTypes[0]?.terminalName]: 0,
    [terminalTypes[1]?.terminalName]: 0,
    [terminalTypes[2]?.terminalName]: 0,
    [terminalTypes[3]?.terminalName]: 0,
    [terminalTypes[4]?.terminalName]: 0,
    [terminalTypes[5]?.terminalName]: 0,
    [terminalTypes[6]?.terminalName]: 0,
    [terminalTypes[7]?.terminalName]: 0,
    [terminalTypes[8]?.terminalName]: 0,
  });
  const [selected, setSelected] = useState([]);
  const [total, setTotal] = useState(0);
  const colors = tokens(theme.palette.mode);
  const { success, snackError } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log(selected, value, merchantDetails, selectedValue);
    const { merchantId, phoneNumber } = value;
    const { officeAddress, settlementBankAccount } = merchantDetails;

    try {
      const response = await httpPost(
        "/terminal-service/api/v1/terminals/request/byAdmin",
        {
          accountToDebit: settlementBankAccount,
          deliveryAddress: officeAddress,
          fullOrPartPayment: "FULL",
          isCharged: Boolean(selectedValue),
          merchantId: merchantId,
          phoneNumber: phoneNumber,
          subTotal: total,
          terminalTypes: selected,
        },
        customBaseUrl.posUrl
      );
      success(response?.respDesc);
    } catch (error) {
      snackError(error)
      console.log(error);
    }
    handleClose();
  };

  const replaceTerminal = (existedTerminal, newTerminal) => {
    setSelected((prevItems) =>
      prevItems.map((item) =>
        item.terminalName === existedTerminal.terminalName ? newTerminal : item
      )
    );
  };

  const handleIncrement = (terminal) => {
    setTerminals((prev) => ({
      ...prev,
      [terminal.terminalName]: prev[terminal.terminalName] + 1,
    }));

    setTotal((prev) => prev + Number(terminal.amount));

    const existedTerminal = selected.filter(
      (option) => option.terminalName == terminal.terminalName
    )[0];

    if (existedTerminal) {
      const newTerminal = {
        ...existedTerminal,
        quantity: existedTerminal.quantity + 1,
      };
      replaceTerminal(existedTerminal, newTerminal);
    } else {
      setSelected((prev) => [...prev, { ...terminal, quantity: 1 }]);
    }
  };

  const handleDecrement = (terminal) => {
    setTerminals((prev) => ({
      ...prev,
      [terminal.terminalName]: prev[terminal.terminalName] - 1,
    }));

    setTotal((prev) => prev - Number(terminal.amount));

    const existedTerminal = selected.filter(
      (option) => option.terminalName == terminal.terminalName
    )[0];

    if (existedTerminal) {
      if (existedTerminal.quantity == 1) {
        setSelected((prev) =>
          prev.filter(
            (item) => item.terminalName !== existedTerminal.terminalName
          )
        );
      } else {
        const newTerminal = {
          ...existedTerminal,
          quantity: existedTerminal.quantity - 1,
        };
        replaceTerminal(existedTerminal, newTerminal);
      }
    }
  };

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
    setSelected([]);
    setValue(null);
    setTerminals({
      [terminalTypes[0]?.terminalName]: 0,
      [terminalTypes[1]?.terminalName]: 0,
      [terminalTypes[2]?.terminalName]: 0,
      [terminalTypes[3]?.terminalName]: 0,
      [terminalTypes[4]?.terminalName]: 0,
      [terminalTypes[5]?.terminalName]: 0,
      [terminalTypes[6]?.terminalName]: 0,
      [terminalTypes[7]?.terminalName]: 0,
      [terminalTypes[8]?.terminalName]: 0,
    });
    setTotal(0);
    setSelectedValue("");
  };

  const getMerchantDetails = async ({ merchantId }) => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapos.ng/agency-service/api/v1/agent/viewmerchantbymerchantid",
        {
          params: {
            merchantId,
          },
        }
      );
      setMerchantDetail(response?.data?.respBody);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (e, value) => {
    setValue(value);
    getMerchantDetails(value);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: "55%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <form onSubmit={handleFormSubmit}>
          <Box padding={5}>
            <Typography
              variant="h3"
              sx={{ textAlign: "center", marginBottom: "40px" }}
            >
              Request For Terminal
            </Typography>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
                marginBottom: "10px",
              }}
            >
              {terminalTypes.map((terminal, index) => {
                return (
                  <CounterField
                    key={index}
                    value={terminals[terminal.terminalName]}
                    name={terminal.terminalName}
                    label={terminal.terminalName}
                    onIncrement={() => handleIncrement(terminal)}
                    onDecrement={() => handleDecrement(terminal)}
                  />
                );
              })}
              <MerchantSearchableSelector
                value={value}
                handleChange={handleSelectChange}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" my={3}>
              <Typography variant="h6" color={colors.grey[300]}>
                Total Terminal Selected:
                <span
                  style={{
                    fontSize: 16,
                    color: colors.grey[100],
                    paddingLeft: 3,
                  }}
                >
                  {selected.length}
                </span>
              </Typography>
              <Typography variant="h6" color={colors.grey[300]}>
                Caution Fee:
                <span
                  style={{
                    fontSize: 16,
                    color: colors.grey[100],
                    paddingLeft: 3,
                  }}
                >
                  {total}
                </span>
              </Typography>
            </Box>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="debitCustomer"
                name="debitCustomer"
                value={selectedValue}
                className={classes.radioGroup}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      sx={{
                        color: colors.orangeAccent[300],
                        "& .MuiSvgIcon-root": {
                          fill: colors.orangeAccent[300],
                        },
                      }}
                    />
                  }
                  label="Debit Customer"
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      sx={{
                        color: colors.orangeAccent[300],
                        "& .MuiSvgIcon-root": {
                          fill: colors.orangeAccent[300],
                        },
                      }}
                    />
                  }
                  label="Dont Debit Customer"
                />
              </RadioGroup>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              color="secondary"
              variant="contained"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "12px 20px",
                marginTop: "20px",
              }}
            >
              Request Terminal
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default RequestTerminalModal;
