import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import SearchableSelector from "../SearchableSelector";
import useAuth from "../../hooks/useAuth";
import { generateSelfPaymentRef } from "../../utils/selfPaymentRef";
import { generateReqId } from "../../utils/generateReqId";
import { useEffect } from "react";

const TransferToOwnAccountModal = ({
  isOpen,
  setModal,
  setOTPModal,
  OTPModal,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success, cookies } = useAuth();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(
        `https://services.staging.wayabank.ng/temporal-service/api/v1/wallet/otp/generate/${cookies?.auth?.user?.phoneNumber}`
      );
      success(response?.data?.data?.message);
      setOTPModal((prev) => ({
        ...prev,
        open: true,
        values: values,
        caller: "ownAccount",
      }));
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const makeTransfer = async () => {
    const { amount } = OTPModal.values;
    const { acct_name, accountNo } = OTPModal.values?.fromUser || {};
    const { accountNo: benefAccountNumber } = OTPModal.values?.toUser || {};
    try {
      const response = await axiosPrivate.post(
        "/logs-service/api/v1/requestlog/create",
        {
          customerName: acct_name,
          payload: JSON.stringify({
            amount,
            benefAccountNumber,
            debitAccountNumber: accountNo,
            paymentReference: generateSelfPaymentRef(),
            tranCrncy: "NGN",
            tranNarration: "Waya self funding",
            tranType: "TRANSFER",
            userId: cookies.auth.user.id,
          }),
          requestId: generateReqId(),
          requestType: "TRANSFERToAccount",
        }
      );
      success(response.data?.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (OTPModal.status?.ownAccount == "success") {
      makeTransfer();
    }
  }, [OTPModal.status?.ownAccount]);

  const initialValues = {
    username: null,
    fromUser: "",
    toUser: "",
    amount: "",
  };

  const checkoutSchema = yup.object().shape({
    username: yup.object().required("Required"),
    fromUser: yup.object().required("Required"),
    toUser: yup.object().required("Required"),
    amount: yup.number().required("Required"),
  });

  const getWallets = async ({ userId }) => {
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/wallet/admin/user/accounts/" + userId
      );
      setOptions(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (e, newValue, setFieldValue) => {
    setFieldValue("username", newValue);
    getWallets(newValue);
  };

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen.open} onClose={handleClose}>
      <Box
        sx={{
          width: 500,
          // height: 480,
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Transfer To Own Account
                </Typography>
                <SearchableSelector
                  value={values.username}
                  name="username"
                  sx={{ gridColumn: "span 2" }}
                  handleChange={(e, newValue) =>
                    handleSelectChange(e, newValue, setFieldValue)
                  }
                />
                <TextField
                  label="Transfer From"
                  fullWidth
                  select
                  name="fromUser"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fromUser}
                  error={!!touched.fromUser && !!errors.fromUser}
                  helperText={touched.fromUser && errors.fromUser}
                  sx={{ marginBottom: "20px", mt: 2 }}
                >
                  {options?.map((option, index) => {
                    const { acct_name, accountNo } = option;
                    return (
                      <MenuItem key={index} value={option}>
                        {acct_name + " - " + accountNo}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  label="Transfer To"
                  fullWidth
                  select
                  name="toUser"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.toUser}
                  error={!!touched.toUser && !!errors.toUser}
                  helperText={touched.toUser && errors.toUser}
                  sx={{ marginBottom: "20px" }}
                >
                  {options?.map((option, index) => {
                    const { acct_name, accountNo } = option;
                    return (
                      <MenuItem key={index} value={option}>
                        {acct_name + " - " + accountNo}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  error={!!touched.amount && !!errors.amount}
                  helperText={touched.amount && errors.amount}
                  sx={{ mb: 4 }}
                />
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isLoading}
                  sx={{ width: "100%", p: 2 }}
                >
                  Transfer
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default TransferToOwnAccountModal;
