import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { all } from "axios";
import { customBaseUrl, httpGet } from "../action/https";

const MerchantSearchableSelector = ({ value, handleChange, sx }) => {
  const [searchKey, setSearchKey] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getMerchants = async () => {
      try {
        const response = await httpGet(
          `${customBaseUrl.posUrl}/wayapos-role-service/api/v1/fetchAllMerchantsAdmin?field=all&page=1&pageSize=1&value=${searchKey}`
        );
        setOptions(response?.data ? response?.data?.content : []);
      } catch (error) {
        console.log(error);
      }
    };

    getMerchants();
  }, [searchKey]);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      options={options}
      getOptionLabel={({ firstName, surname, email, merchantId }) =>
        firstName + " " + surname + " - " + email + " - " + merchantId
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onChange={(e) => setSearchKey(e.target.value)}
            label="Search User"
            variant="outlined"
          />
        );
      }}
      sx={sx}
    />
  );
};

export default MerchantSearchableSelector;
