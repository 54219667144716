import React from "react";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SearchableSelector from "../../SearchableSelector";
import { generateReqId } from "../../../utils/generateReqId";
import { generatePaymentRef } from "../../../utils/generatePaymentRef";

const PayWithPhone = ({ handleClose, setOTPModal, OTPModal }) => {
  const { success, snackError, cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [options, setOptions] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(
        `https://services.staging.wayabank.ng/temporal-service/api/v1/wallet/otp/generate/${cookies?.auth?.user?.phoneNumber}`
      );
      success(response?.data?.data?.message);
      setOTPModal((prev) => ({
        ...prev,
        open: true,
        values: values,
        caller: "payToPhone",
      }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }

    // handleClose();
  };

  const makeTransfer = async () => {
    const { amount, recipientType, recipientPhone, recipientName, narration } =
      OTPModal.values;
    const { acct_name, accountNo } = OTPModal.values?.fromUser || {};
    const { userId } = OTPModal.values?.username || {};

    if (recipientType == "Non Waya User") {
      try {
        const response = await axiosPrivate.post(
          "/logs-service/api/v1/requestlog/create",
          {
            customerName: acct_name,
            payload: JSON.stringify({
              tranNarration: narration,
              tranCrncy: "NGN",
              fullName: recipientName,
              amount: amount,
              senderId: cookies.auth.user.id,
              customerDebitAccountNo: accountNo,
              paymentReference: accountNo + generatePaymentRef(),
              emailOrPhoneNo: recipientPhone,
            }),
            requestId: generateReqId(),
            requestType: "TRANSFERToNonWaya",
          }
        );
        success(response.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axiosPrivate.post(
          "/logs-service/api/v1/requestlog/create",
          {
            customerName: acct_name,
            payload: JSON.stringify({
              description: narration,
              amount,
              senderId: userId,
              senderAccountNumber: accountNo,
              beneficiaryPhoneNo: recipientPhone,
            }),
            requestId: generateReqId(),
            requestType: "TRANSFERToPhone",
          }
        );
        success(response.data?.message);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (OTPModal.status?.payToPhone == "success") {
      makeTransfer();
    }
  }, [OTPModal.status?.payToPhone]);

  const getWallets = async ({ userId }) => {
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/wallet/admin/user/accounts/" + userId
      );
      setOptions(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (e, newValue, setFieldValue) => {
    setFieldValue("username", newValue);
    getWallets(newValue);
  };

  const initialValues = {
    username: null,
    recipientType: "",
    recipientName: "",
    recipientPhone: "",
    amount: "",
    narration: "",
    pin: "",
    fromUser: "",
  };

  const checkoutSchema = yup.object().shape({
    username: yup.object().required("Required"),
    recipientType: yup.string().required("Required"),
    recipientName: yup.string().when("recipientType", {
      is: (value) => value === "Non Waya User",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    recipientPhone: yup.number().required("Required"),
    amount: yup.number().required("Required"),
    narration: yup.string(),
    pin: yup.number().required("Required"),
    fromUser: yup.object().required("Required"),
  });

  const userTypes = [
    {
      name: "Waya User",
    },
    {
      name: "Non Waya User",
    },
  ];

  return (
    <Box width="100%">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box padding={5}>
              <Typography
                variant="h3"
                sx={{ textAlign: "center", marginBottom: "30px" }}
              >
                Pay With Phone
              </Typography>
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                  marginBottom: "10px",
                }}
              >
                <SearchableSelector
                  value={values.username}
                  name="username"
                  sx={{ gridColumn: "span 2" }}
                  handleChange={(e, newValue) =>
                    handleSelectChange(e, newValue, setFieldValue)
                  }
                />
                <TextField
                  label="Recipient Type"
                  fullWidth
                  select
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.recipientType}
                  name="recipientType"
                  sx={{ gridColumn: "span 2" }}
                >
                  {userTypes.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                {values.recipientType == "Non Waya User" && (
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Recipient Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.recipientName}
                    name="recipientName"
                    error={!!touched.recipientName && !!errors.recipientName}
                    helperText={touched.recipientName && errors.recipientName}
                    sx={{ gridColumn: "span 2" }}
                  />
                )}
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Recipient's Phone Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.recipientPhone}
                  name="recipientPhone"
                  error={!!touched.recipientPhone && !!errors.recipientPhone}
                  helperText={touched.recipientPhone && errors.recipientPhone}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  error={!!touched.amount && !!errors.amount}
                  helperText={touched.amount && errors.amount}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Add a note"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.narration}
                  name="narration"
                  error={!!touched.narration && !!errors.narration}
                  helperText={touched.narration && errors.narration}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Pin"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pin}
                  name="pin"
                  error={!!touched.pin && !!errors.pin}
                  helperText={touched.pin && errors.pin}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Transfer From"
                  fullWidth
                  select
                  name="fromUser"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fromUser}
                  sx={{ gridColumn: "span 4" }}
                >
                  {options?.map((option, index) => {
                    const { acct_name, accountNo } = option;
                    return (
                      <MenuItem key={index} value={option}>
                        {acct_name + " - " + accountNo}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
              <Button
                type="submit"
                color="secondary"
                disabled={isLoading}
                variant="contained"
                sx={{ width: "100%", p: 2, mt: 2 }}
              >
                Proceed
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default PayWithPhone;
