import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
  Typography,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import SearchTable from "../../components/SearchTable";
import NewTerminalModal from "../../components/modals/NewTerminalModal";
import RequestTerminalModal from "../../components/modals/RequestModal";
import WarningModal from "../../components/modals/WarningModal";
import RejectRequestModal from "../../components/modals/RejectRequestModal";
import useAuth from "../../hooks/useAuth";
import IssueNewTerminalModal from "../../components/modals/IssueTerminalModal";
import { customBaseUrl, httpGet, httpPost } from "../../action/https";

const ManagePos = () => {
  const { snackError } = useAuth();
  const [details, setDetails] = useState({});
  const [newDetails, setNewDetails] = useState([]);
  const [newRequestDetails, setNewRequestDetails] = useState([]);
  const [modal, setModal] = useState({ open: false });
  const [requestModal, setRequestModal] = useState({ open: false });
  const [value, setValue] = useState(0);
  const [issueTerminalModal, setIssueTerminalModal] = useState({ open: false });
  const [warning, setWarning] = useState({
    openConfirm: false,
    url: "",
    user: {},
    action: "",
  });
  const [reject, setReject] = useState({
    open: false,
    url: "",
    user: {},
    action: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getTerminalTypes = async () => {
    try {
      const response = await httpGet(`${customBaseUrl.posUrl}/terminal-service/api/v1/terminals/getterminaltypes`)
      return response?.respBody;
    } catch (error) {
      console.log(error);
      snackError(error)
    }
  };

  const getTerminalByParams = async () => {
    try {
      // const response = await axiosPrivate.post(
      //   "https://services.staging.wayapos.ng/terminal-service/api/v1/terminals/viewallterminalbyparam",
      //   {
      //     field: "all",
      //     pageNo: 0,
      //     pageSize: 20,
      //     value: "all",
      //   }
      // );
      const response = await httpPost('/terminal-service/api/v1/terminals/viewallterminalbyparam',
        {
          field: "all",
          pageNo: 0,
          pageSize: 50,
          value: "all",
        },
        customBaseUrl.posUrl
      )
      setNewDetails(response?.respBody?.content);
      return response?.data?.respBody?.content;
    } catch (error) {
      console.log(error);
      snackError(error)
    }
  };

  const getTerminalRequests = async () => {
    try {
      // const response = await axiosPrivate.post(
      //   "https://services.staging.wayapos.ng/terminal-service/api/v1/terminals/viewallrequestedterminalbyparam",
      //   {
      //     field: "all",
      //     pageNo: 0,
      //     pageSize: 20,
      //     value: "all",
      //   }
      // );
      const response = await httpPost('/terminal-service/api/v1/terminals/viewallrequestedterminalbyparam',
        {
          field: "all",
          pageNo: 0,
          pageSize: 50,
          value: "all",
        },
        customBaseUrl.posUrl
      )
      setNewRequestDetails(response?.respBody?.content);
      return response?.respBody?.content;
    } catch (error) {
      if (error?.response?.status == 400) {
        snackError(error?.response?.respBody);
      }
      console.log(error);
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.terminalByParams?.filter(
        (transaction) =>
          transaction.terminalId === searchKey ||
          transaction.terminalName === searchKey ||
          transaction.assignedTo === searchKey
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRequestSearch = async ({ searchKey }) => {
    try {
      let result = details?.terminalRequests?.filter(
        (transaction) =>
          transaction.userId === searchKey ||
          transaction.id === searchKey ||
          transaction.requestedBy === searchKey
      );
      setNewRequestDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const QRDetails = async () => {
      const [terminalByParams, terminalTypes, terminalRequests] =
        await Promise.all([
          getTerminalByParams(),
          getTerminalTypes(),
          getTerminalRequests(),
        ]);

      setDetails({
        terminalByParams,
        terminalTypes,
        terminalRequests,
      });

      setNewRequestDetails(
        details?.terminalRequests?.filter(
          (request) => request.status !== "ISSUED"
        )
      );
      setIsLoading(false);
    };
    QRDetails();
  }, [value]);

  const columns = [
    { field: "terminalId", headerName: "Terminal Id", width: 150 },
    {
      field: "terminalName",
      headerName: "Terminal Name",
      width: 150,
    },
    {
      field: "terminalAmount",
      headerName: "Terminal Cost",
      cellClassName: "name-column--cell",
      width: 100,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      headerAlign: "left",
      align: "left",
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      width: 100,
      renderCell: ({ row: { status } }) => {
        return (
          <Typography
            color={
              status == "ACTIVE"
                ? colors.greenAccent[400]
                : colors.redAccent[400]
            }
          >
            {status}
          </Typography>
        );
      },
    },
    {
      field: "issuedDate",
      headerName: "Issue Date",
      width: 100,
    },
    {
      field: "action",
      headerName: "Actions",
      with: 100,
    },
  ];

  const columns2 = [
    { field: "userId", headerName: "Merchant Id", width: 80 },
    {
      field: "id",
      headerName: "Request Id",
      width: 80,
    },
    {
      field: "requestedBy",
      headerName: "Requested By",
      width: 200,
    },
    {
      field: "merchantName",
      headerName: "Request For",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "terminalName",
      headerName: "Terminal Name",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      cellClassName: "name-column--cell",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      with: 100,
      renderCell: ({ row: { status } }) => {
        return (
          <Typography
            color={
              status == "APPROVED"
                ? colors.greenAccent[400]
                : colors.redAccent[400]
            }
          >
            {status}
          </Typography>
        );
      },
    },
    {
      field: "dateCreated",
      headerName: "Request Date",
      with: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      with: 100,
      renderCell: ({ row }) => {
        return (
          <Box>
            <IconButton
              onClick={() =>
                setWarning({
                  openConfirm: true,
                  url: "https://services.staging.wayapos.ng/terminal-service/api/v1/terminals/updateterminalrequest",
                  user: row,
                  action: "accept request",
                })
              }
              sx={{
                color: colors.greenAccent[500],
                fontSize: "14px",
              }}
            >
              <CheckCircleIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                setReject({
                  open: true,
                  url: "https://services.staging.wayapos.ng/terminal-service/api/v1/terminals/updateterminalrequest",
                  user: row,
                  action: "reject request",
                })
              }
              sx={{
                color: colors.redAccent[500],
                fontSize: "14px",
              }}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <WarningModal
        open={warning.openConfirm}
        url={warning.url}
        user={warning.user}
        setOpen={setWarning}
        action={warning.action}
      />

      <RejectRequestModal
        isOpen={reject.open}
        setIsOpen={setReject}
        url={reject.url}
        user={reject.user}
        action={reject.action}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tabs value={value} onChange={handleChange} sx={{ borderRadius: 3 }}>
          <Tab
            disabled={isLoading}
            label="POS DEVICES"
            sx={{
              bgcolor: colors.primary[400],
              "&.Mui-selected": {
                color: colors.grey[100],
                bgcolor: colors.orangeAccent[700],
              },
            }}
          />
          <Tab
            disabled={isLoading}
            label="POS REQUESTS"
            sx={{
              bgcolor: colors.primary[400],
              "&.Mui-selected": {
                color: colors.grey[100],
                bgcolor: colors.orangeAccent[700],
              },
            }}
          />
        </Tabs>

        {/*  T A B S    S E P E R A T O R   */}

        {value === 0 && (
          <Box width="95%">
            <NewTerminalModal
              isOpen={modal.open}
              setModal={setModal}
              title={modal.title}
              terminalTypes={details?.terminalTypes}
            />
            <Box
              display={smScreen ? "flex" : "block"}
              flexDirection={smScreen ? "row" : "column"}
              justifyContent={smScreen ? "space-between" : "start"}
              alignItems={smScreen ? "center" : "start"}
              margin="10px 0"
            >
              <Header title="POS DEVICES" subtitle="Manage POS Devices" />

              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display="flex">
                  <SearchTable
                    handleSearch={handleSearch}
                    placeholder="Id/Name/AssignedTo"
                  />
                  <Button
                    onClick={() =>
                      setModal({
                        open: true,
                        title: "Add New Terminal",
                      })
                    }
                    underline="none"
                    disabled={isLoading}
                    sx={{
                      backgroundColor: colors.orangeAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      marginLeft: "15px",
                    }}
                  >
                    Add New Terminal
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* GRID & CHARTS */}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Box
                m="10px"
                width="100%"
                height="65vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.orangeAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.redAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.redAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.orangeAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}
              >
                <DataGrid
                  getRowId={(row) => {
                    return row.id;
                  }}
                  rows={newDetails}
                  columns={columns}
                  // components={{ Toolbar: GridToolbar }}
                />
              </Box>
            </Grid>
          </Box>
        )}

        {value === 1 && (
          <Box width="95%">
            <RequestTerminalModal
              isOpen={requestModal.open}
              setModal={setRequestModal}
              terminalTypes={details?.terminalTypes}
            />
            <IssueNewTerminalModal
              modal={issueTerminalModal}
              setModal={setIssueTerminalModal}
            />
            <Box
              display={smScreen ? "flex" : "block"}
              flexDirection={smScreen ? "row" : "column"}
              justifyContent={smScreen ? "space-between" : "start"}
              alignItems={smScreen ? "center" : "start"}
              m="10px 0"
            >
              <Header title="POS REQUESTS" subtitle="Manage POS Requests" />

              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display="flex">
                  <SearchTable handleSearch={handleRequestSearch} />
                  <Button
                    onClick={() =>
                      setIssueTerminalModal({
                        open: true,
                      })
                    }
                    underline="none"
                    disabled={isLoading}
                    sx={{
                      backgroundColor: colors.orangeAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      marginLeft: "15px",
                    }}
                  >
                    Issue New Terminal
                  </Button>
                  <Button
                    onClick={() =>
                      setRequestModal({
                        open: true,
                      })
                    }
                    underline="none"
                    disabled={isLoading}
                    sx={{
                      backgroundColor: colors.orangeAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      marginLeft: "15px",
                    }}
                  >
                    Request For Terminal
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* GRID & CHARTS */}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Box
                m="10px"
                width="100%"
                height="65vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.orangeAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.redAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.redAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.orangeAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}
              >
                <DataGrid
                  getRowId={(row) => {
                    return row.id;
                  }}
                  rows={newRequestDetails ? newRequestDetails : []}
                  columns={columns2}
                  // components={{ Toolbar: GridToolbar }}
                />
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ManagePos;
