import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { tokens } from "../../theme";
import { generateReqId } from "../../utils/generateReqId";
import naijaStateLocalGovernment from "naija-state-local-government";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";

const MoreFormModal = ({ isOpen, setModal, title }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [businessTypes, setBusinessTypes] = useState([]);

  const handleFormSubmit = async (values) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      accountType,
      dateOfBirth,
      gender,
      password,
      referenceCode,
      organisationName,
      organisationEmail,
      organisationPhone,
      organisationState,
      organisationCity,
      organizationAddress,
      businessType,
    } = values;
    try {
      const response = await axiosPrivate.post(
        "/logs-service/api/v1/requestlog/create",
        {
          customerName: `${firstName} ${lastName}`,
          payload: JSON.stringify({
            admin: false,
            orgEmail: organisationEmail,
            orgPhone: organisationPhone,
            orgName: organisationName,
            businessType: businessType,
            officeAddress: organizationAddress,
            state: organisationState,
            city: organisationCity,
            email: email,
            firstName: firstName,
            surname: lastName,
            dateOfBirth: new Date(dateOfBirth).toISOString().split("T")[0],
            gender,
            phoneNumber,
            password,
            referenceCode,
          }),
          requestId: generateReqId(),
          requestType: "createUser",
        }
      );
      success(response.data.message);
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };

  const initialValues = {
    organisationName: "",
    organisationEmail: "",
    organisationPhone: "",
    organisationState: "",
    organisationCity: "",
    organizationAddress: "",
    businessType: "",
    accountType: "Individual",
    email: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    phoneNumber: "",
    password: "",
    referenceCode: "",
  };

  const checkoutSchema = yup.object().shape({
    organisationName: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    organisationEmail: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().email().required("Required"),
      otherwise: yup.string().email(),
    }),
    organisationPhone: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    organisationState: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    organisationCity: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    organizationAddress: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    businessType: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    accountType: yup.string().required("Required"),
    email: yup.string().email().required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    dateOfBirth: yup.string(),
    gender: yup.string().required("Required"),
    phoneNumber: yup.string().required("Required"),
    password: yup.string().required("Required"),
    referenceCode: yup.string(),
  });

  const options = [
    {
      name: "Individual",
    },
    {
      name: "Corporate",
    },
  ];

  const genders = [
    {
      name: "MALE",
    },
    {
      name: "FEMALE",
    },
  ];

  const getBusinessIndustries = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapay.ng/identity-manager-service/api/v1/waya-merchant/business-kyc/business-industry"
      );
      setBusinessTypes(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBusinessIndustries();
  }, []);

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  {title}
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    label="Account Type"
                    fullWidth
                    select
                    name="accountType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.accountType}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {options.map((option, index) => (
                      <MenuItem key={index} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  {values.accountType == "Corporate" && (
                    <>
                      <TextField
                        fullWidth
                        select
                        label="Business Type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.businessType}
                        name="businessType"
                        error={!!touched.businessType && !!errors.businessType}
                        helperText={touched.businessType && errors.businessType}
                        sx={{ gridColumn: "span 2" }}
                      >
                        {businessTypes.map((type, index) => (
                          <MenuItem key={index} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Organisation Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organisationName}
                        name="organisationName"
                        error={
                          !!touched.organisationName &&
                          !!errors.organisationName
                        }
                        helperText={
                          touched.organisationName && errors.organisationName
                        }
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Organisation Email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organisationEmail}
                        name="organisationEmail"
                        error={
                          !!touched.organisationEmail &&
                          !!errors.organisationEmail
                        }
                        helperText={
                          touched.organisationEmail && errors.organisationEmail
                        }
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        label="Organisation Phone Number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organisationPhone}
                        name="organisationPhone"
                        error={
                          !!touched.organisationPhone &&
                          !!errors.organisationPhone
                        }
                        helperText={
                          touched.organisationPhone && errors.organisationPhone
                        }
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        select
                        label="Organisation State"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organisationState}
                        name="organisationState"
                        error={
                          !!touched.organisationState &&
                          !!errors.organisationState
                        }
                        helperText={
                          touched.organisationState && errors.organisationState
                        }
                        sx={{ gridColumn: "span 2" }}
                      >
                        {naijaStateLocalGovernment
                          .states()
                          .map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </TextField>
                      <TextField
                        fullWidth
                        select
                        label="Organisation City"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organisationCity}
                        error={
                          !!touched.organisationCity &&
                          !!errors.organisationCity
                        }
                        helperText={
                          touched.organisationCity && errors.organisationCity
                        }
                        name="organisationCity"
                        sx={{ gridColumn: "span 2" }}
                      >
                        {values?.organisationState
                          ? naijaStateLocalGovernment
                              .lgas(values?.organisationState)
                              .lgas?.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))
                          : []}
                      </TextField>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Organisation Address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organizationAddress}
                        error={
                          !!touched.organizationAddress &&
                          !!errors.organizationAddress
                        }
                        helperText={
                          touched.organizationAddress &&
                          errors.organizationAddress
                        }
                        name="organizationAddress"
                        sx={{ gridColumn: "span 2" }}
                      />
                    </>
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="First Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    name="firstName"
                    error={!!touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Last Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    name="lastName"
                    error={!!touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <Box sx={{ gridColumn: "span 2" }}>
                    <ReactDatePicker
                      selected={values.dateOfBirth}
                      onChange={(date) => setFieldValue("dateOfBirth", date)}
                      name="dateOfBirth"
                      dateFormat="d MMMM, yyyy"
                      customInput={
                        <TextField
                          fullWidth
                          InputProps={{ readOnly: true }}
                          label="Date of Birth"
                          error={!!touched.dateOfBirth && !!errors.dateOfBirth}
                          helperText={touched.dateOfBirth && errors.dateOfBirth}
                        />
                      }
                    />
                  </Box>
                  <TextField
                    label="Gender"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.gender}
                    name="gender"
                    error={!!touched.gender && !!errors.gender}
                    helperText={touched.gender && errors.gender}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {genders.map((gender, index) => (
                      <MenuItem key={index} value={gender.name}>
                        {gender.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneNumber}
                    name="phoneNumber"
                    error={!!touched.phoneNumber && !!errors.phoneNumber}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Reference"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.referenceCode}
                    name="referenceCode"
                    error={!!touched.referenceCode && !!errors.referenceCode}
                    helperText={touched.referenceCode && errors.referenceCode}
                    sx={{ gridColumn: "span 2" }}
                  />
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 2 }}
                >
                  Create User
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default MoreFormModal;
