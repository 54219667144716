import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import RejectRequestModal from "../../components/modals/RejectRequestModal";
import WarningModal from "../../components/modals/WarningModal";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../action/https";

const WayaPosRequestLogs = () => {
  const [details, setDetails] = useState([]);
  const [newDetails, setNewDetails] = useState([]);
  const [rejectModal, setRejectModal] = useState({
    open: false,
    url: "",
    user: {},
  });
  const [modal, setModal] = useState({
    openConfirm: false,
    url: "",
    action: "",
  });
  const { success, snackError, cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getRequestLogs = async () => {
    try {
      // const response = await axiosPrivate.get(
      //   "https://services.staging.wayapos.ng/auditlog-service/api/v1/requestLogs/byAdmin"
      // );
      const response = await httpGet(`${customBaseUrl.posUrl}/auditlog-service/api/v1/requestLogs/byAdmin`)
      setDetails(response?.respBody?.content);
    } catch (error) {
      if (error.response.status === 403) {
        snackError("Not Authorized");
      } else {
        console.log(error);
      }
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.filter(
        (request) =>
          request?.id === Number(searchKey) ||
          request?.requestBy?.toLowerCase() === searchKey.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleReject = async (row) =>  {
    const res = await httpPut(`/auditlog-service/api/v1/updateLog/${row?.id}`,
      {
        reason: 'Rejected by admin',
        status: "REJECTED",
      },
      customBaseUrl.posUrl
    )
    if (res?.respCode === 0) {
      success(res?.respDesc);
    } else {
      snackError(res?.respDesc);
    }
    getRequestLogs();
  }
  const handleAccept = async(row) =>  {
    // auditlog-service/api/v1/updateLog/906
    const res =await httpPut(`/auditlog-service/api/v1/updateLog/${row?.id}`,
      {
        status: "APPROVED",
      },
      customBaseUrl.posUrl
    )
    if (res?.respCode === 0) {
      success(res?.respDesc);
    } else {
      snackError(res?.respDesc);
    }
    getRequestLogs();
  }
  useEffect(() => {
    getRequestLogs();
  }, []);

  const columns = [
    { field: "id", headerName: "Request Id", width: 100 },
    {
      field: "requestBy",
      headerName: "Requested By",
      width: 200,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 200,
    },
    {
      field: "requestType",
      headerName: "Request Type",
      headerAlign: "left",
      align: "left",
      width: 160,
      renderCell: ({ row: { requestType } }) =>
        requestType
          .split("_")
          .map((word) => word.charAt(0) + word.substring(1).toLowerCase())
          .join(" "),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: ({ row: { status } }) => {
        return (
          <Typography
            color={
              status == "PENDING_AUTHORIZER"
                ? colors.redAccent[400]
                : colors.greenAccent[400]
            }
          >
            {status
              .split("_")
              .map((word) => word.charAt(0) + word.substring(1).toLowerCase())
              .join(" ")}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      renderCell: ({ row: { createdAt } }) => {
        return new Date(createdAt).toLocaleString();
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Button
              onClick={() =>{
                handleAccept(row);
                // setModal({
                //   openConfirm: true,
                //   user: row,
                //   action: "accept",
                //   url: "/activity-logservice/api/v1/requestlog/process-request",
                // })
              }}
              disabled={row.status === "APPROVED"}
              underline="none"
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
              }}
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                handleReject(row)
                // setRejectModal({
                //   open: true,
                //   user: row,
                //   url: "/activity-logservice/api/v1/requestlog/process-request",
                // })
              }}
              disabled={row.status === "DECLINED"}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                ml: 1,
                color: colors.grey[100],
              }}
            >
              Rejectl
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}

      <RejectRequestModal
        isOpen={rejectModal.open}
        setIsOpen={setRejectModal}
        url={rejectModal.url}
        user={rejectModal.user}
      />

      <WarningModal
        open={modal.openConfirm}
        action={modal.action}
        url={modal.url}
        user={modal.user}
        setOpen={setModal}
      />

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="REQUEST LOGS" subtitle="Manage WayaPos Request Logs" />

        <Box display={"flex"} justifyContent={"space-between"}>
          <SearchTable handleSearch={handleSearch} placeholder="id/requestBy" />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Box
          m="10px"
          width="100%"
          height="63vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={details ? details : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default WayaPosRequestLogs;
