import React, { useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { tokens } from "../../theme";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import { customBaseUrl, httpGet, httpPost } from "../../action/https";

const IssueNewTerminalModal = ({ modal, setModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError, cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [approvedMerchant, setApprovedMerchant] = useState([]);
  const [approvedTerminal, setApprovedTerminal] = useState([]);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    console.log(values);
    const { terminalSerialNumber } = values.serialNumber;
    const { id, userId } = values.merchant;

    try {
      const response = await httpPost(
        "/terminal-service/api/v1/terminals/issueterminal",
        {
          requestId: id,
          terminalSerialNumber,
          userId: Number(userId),
        },
        customBaseUrl.posUrl
      );
      if (response.respCode === 0 ){
      success(response?.respDesc);
      } else {
        snackError(response?.respBody);
      }
      handleClose();
    } catch (error) {
      if (error.response) {
        snackError(error.response?.respBody);
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  const getApprovedMerchant = async () => {
    try {
      const response = await httpGet(
        `${customBaseUrl.posUrl}/terminal-service/api/v1/terminals/request/getAllApprovedUsers`
      );
      setApprovedMerchant(response?.respBody);
    } catch (error) {
      console.log(error);
    }
  };

  const getApprovedTerminal = async ({ terminalName }) => {
    try {
      const response = await httpGet(
        `${customBaseUrl.posUrl}/terminal-service/api/v1/terminals/findunassignedterminals?terminalName=${terminalName}`
      );
      if (response?.respCode === 0 ) {
      setApprovedTerminal(response?.respBody);
      success('success');
      } else {
        snackError(response?.respBody)
      }
    } catch (error) {
      if (error.response) {
        snackError(error.response?.respBody);
        setApprovedTerminal([]);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    getApprovedMerchant();
  }, []);

  const initialValues = {
    merchant: null,
    serialNumber: null,
    terminalCost: "",
    amountPaid: "",
    actualTerminalName: "",
    preferredTerminalName: "",
  };

  const checkoutSchema = yup.object().shape({
    merchant: yup.object().required("Required"),
    serialNumber: yup.object().required("Required"),
    terminalCost: yup.number().required("Required"),
    amountPaid: yup.number(),
    actualTerminalName: yup.string().required("Required"),
    preferredTerminalName: yup.string().required("Required"),
  });

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const handleTerminalSelect = (event, setFieldValue) => {
    const {
      terminalAmount,
      amountPaid,
      actualTerminalName,
      preferredTerminalName,
    } = event.target.value;
    setFieldValue("terminalCost", terminalAmount);
    setFieldValue("amountPaid", amountPaid ? amountPaid : 0);
    setFieldValue("actualTerminalName", actualTerminalName);
    setFieldValue("preferredTerminalName", preferredTerminalName);
  };

  const handleSelectChange = (event, setFieldValue) => {
    const selected = event.target.value;
    setFieldValue("merchant", selected);
    getApprovedTerminal(selected);
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Issue a new POS Terminal
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    label="Select Merchant"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={(e) => handleSelectChange(e, setFieldValue)}
                    value={values.merchant}
                    name="merchant"
                    error={!!touched.merchant && !!errors.merchant}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {approvedMerchant?.map((merchant, index) => (
                      <MenuItem key={index} value={merchant}>
                        {merchant?.merchantName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Serial Number"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleTerminalSelect(e, setFieldValue);
                    }}
                    value={values.serialNumber}
                    name="serialNumber"
                    error={!!touched.serialNumber && !!errors.serialNumber}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {approvedTerminal?.map((terminal, index) => (
                      <MenuItem key={index} value={terminal}>
                        {terminal?.terminalName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    disabled
                    type="number"
                    label="Terminal Cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.terminalCost}
                    name="terminalCost"
                    error={!!touched.terminalCost && !!errors.terminalCost}
                    helperText={touched.terminalCost && errors.terminalCost}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    disabled
                    type="number"
                    label="Amount Paid"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.amountPaid}
                    name="amountPaid"
                    error={!!touched.amountPaid && !!errors.amountPaid}
                    helperText={touched.amountPaid && errors.amountPaid}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Actual Terminal Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.actualTerminalName}
                    actualTerminalName="actualTerminalName"
                    error={
                      !!touched.actualTerminalName &&
                      !!errors.actualTerminalName
                    }
                    helperText={
                      touched.actualTerminalName && errors.actualTerminalName
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Preferred Terminal Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.preferredTerminalName}
                    preferredTerminalName="preferredTerminalName"
                    error={
                      !!touched.preferredTerminalName &&
                      !!errors.preferredTerminalName
                    }
                    helperText={
                      touched.preferredTerminalName &&
                      errors.preferredTerminalName
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 3 }}
                >
                  Add Terminal
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default IssueNewTerminalModal;
