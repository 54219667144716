import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import SearchableSelectTransfer from "../../SearchableSelectTransfer";
import SearchableSelector from "../../SearchableSelector";
import { generateReqId } from "../../../utils/generateReqId";

const DataModal = ({ modal, setModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [serviceProviders, setServiceProviders] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selected, setSelected] = useState("");
  const [options, setOptions] = useState([]);
  const { success, snackError } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    const {
      amount,
      serviceProvider,
      phoneNumber,
      plan,
      wallet: { accountNo },
      username: { userId, firstName, surname },
    } = values;
    try {
      const response = await axiosPrivate.post(
        "/logs-service/api/v1/requestlog/create",
        {
          customerName: `${firstName} ${surname}`.toUpperCase(),
          payload: JSON.stringify({
            amount,
            billerId: serviceProvider,
            categoryId: "databundle",
            data: [
              { name: "phone", value: phoneNumber },
              { name: "amount", value: amount },
              { name: "total_amount", value: amount },
              { name: "account_number", value: "" },
              { name: "smartcard_number", value: "" },
              { name: "plan", value: plan },
              { name: "bundles", value: plan },
              { name: "account_number", value: "" },
              { name: "numberOfPins", value: "" },
            ],
            sourceWalletAccountNumber: accountNo,
            userId: Number(userId),
          }),
          requestId: generateReqId(),
          requestType: "BILLSPAYMENT",
        }
      );
      console.log(response);
    } catch (error) {
      snackError(error.message);
    }
    setIsLoading(false);
    resetForm();
    handleClose();
  };

  const initialValues = {
    username: null,
    serviceProvider: "",
    plan: "",
    phoneNumber: "",
    amount: "",
    wallet: "",
  };
  const checkoutSchema = yup.object().shape({
    username: yup.object().required("Required"),
    serviceProvider: yup.string().required("Required"),
    plan: yup.string().required("Required"),
    phoneNumber: yup.number().required("Required"),
    amount: yup.number().required("Required"),
    wallet: yup.object().required("Required"),
  });

  useEffect(() => {
    const getServiceProvider = async () => {
      try {
        const response = await axiosPrivate.get(
          "/billspayment-service/api/v1/category/databundle"
        );
        setServiceProviders(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getServiceProvider();
  }, []);

  useEffect(() => {
    const getPlan = async () => {
      try {
        const response = await axiosPrivate.get(
          "/billspayment-service/api/v1/category/databundle/biller/" + selected
        );
        setPlans(response?.data?.data?.items[2]?.subItems);
      } catch (error) {
        console.log(error);
      }
    };
    getPlan();
  }, [selected]);

  const handleServiceProviderChange = (e) => {
    setSelected(e?.target?.value);
  };
  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const getWallets = async ({ userId }) => {
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/wallet/admin/user/accounts/" + userId
      );
      setOptions(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (e, newValue, setFieldValue) => {
    setFieldValue("username", newValue);
    getWallets(newValue);
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          //   height: "40%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "20px" }}
                >
                  Recharge Data
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <SearchableSelector
                    value={values.username}
                    name="username"
                    sx={{ gridColumn: "span 2" }}
                    handleChange={(e, newValue) =>
                      handleSelectChange(e, newValue, setFieldValue)
                    }
                  />
                  <TextField
                    label="Service Provider"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleServiceProviderChange(e);
                    }}
                    value={values.serviceProvider}
                    name="serviceProvider"
                    error={
                      !!touched.serviceProvider && !!errors.serviceProvider
                    }
                    helperText={
                      touched.serviceProvider && errors.serviceProvider
                    }
                    sx={{ gridColumn: "span 2" }}
                  >
                    {serviceProviders?.map((option, index) => (
                      <MenuItem key={index} value={option.billerId}>
                        {option.billerName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Plan"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.plan}
                    name="plan"
                    error={!!touched.plan && !!errors.plan}
                    helperText={touched.plan && errors.plan}
                    sx={{ gridColumn: "span 4" }}
                  >
                    {plans?.map((option, index) => (
                      <MenuItem key={index} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Phone Number"
                    fullWidth
                    variant="filled"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneNumber}
                    name="phoneNumber"
                    error={!!touched.phoneNumber && !!errors.phoneNumber}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.amount}
                    name="amount"
                    error={!!touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    select
                    label="Select Wallet"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.wallet}
                    name="wallet"
                    error={!!touched.wallet && !!errors.wallet}
                    helperText={touched.wallet && errors.wallet}
                    sx={{ gridColumn: "span 4" }}
                  >
                    {options?.map((option, index) => {
                      const { acct_name, accountNo } = option;
                      return (
                        <MenuItem key={index} value={option}>
                          {acct_name + " - " + accountNo}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 2 }}
                >
                  Transfer
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default DataModal;
