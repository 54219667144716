// import dotenv from 'dotenv';

// dotenv.config();

const {
  IS_LIVE,
  REACT_APP_WAYA_BANK,
  REACT_APP_WAYA_QUICK,
  REACT_APP_WAYA_POS,
} = process.env;

const envConfig = {
  isLive: IS_LIVE === 'live' || false,
  baseUrl: REACT_APP_WAYA_BANK,
  quickUrl: REACT_APP_WAYA_QUICK,
  posUrl: REACT_APP_WAYA_POS,
};

export default envConfig;
